/* eslint-disable no-undef */

import React from 'react';
import { CSVLink } from "react-csv";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const HideElement = styled.div`
  display: block;
`; 

class CSVMaker extends React.Component {
  componentDidMount() {
    setTimeout(() => toast.error(`${this.props.data.length} invitations not sent.`), 1000)
    setTimeout(() => this.inputElement.link.click(), 2000 );
    setTimeout(() => this.props.removeCSVData(), 3000)
  }

  render() {
    const headers = [
      { label: "Email", key: "email" }
    ];
       
    return (
      <HideElement>
        <CSVLink
          data={this.props.data}
          headers = {headers}
          filename={"employee-list.csv"}
          target="_blank" 
          ref={(input) => this.inputElement = input}
        />
      </HideElement>
    )
  }
}

CSVMaker.propTypes = {
  data: PropTypes.array,
  removeCSVData: PropTypes.func
}

export default CSVMaker;