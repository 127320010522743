/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledModal } from '../PeopleHomeV2/ManageBuddies/styles';
import {CalendarHeader} from '../ChallengeDetailsV2/InviteAmigoPopUp/styles';
import {PopupWrapper, ButtonWrapper} from './styles';
import _ from 'lodash';
import Waiting from '../Waiting';
import { ImageUrl } from "../../utils/constants";
import { withTranslation } from 'react-i18next';
import parse from 'react-html-parser';

class AboutChallengePopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { show, challengeData, userName, hideAboutChallengePopup, history } = this.props;
    if (_.isEmpty(challengeData)|| _.isUndefined(challengeData)) {
      return <Waiting />
    }
    const challengeName = challengeData.challenge_type=="new_group"?"Group":challengeData.challenge_type=="group"?"Classic":challengeData.challenge_type=="survivor"?"Survivor":"Team";
    window.console.log("challenge data is",challengeData)
    return (
      <StyledModal show={show} width={"600px"} height="auto">
        <CalendarHeader>
          <div className="title"> {`${this.props.t("Launch a")} ${this.props.t(challengeName)} ${this.props.t("Challenge")}`}</div>
          <div className="closeButton" onClick={() => hideAboutChallengePopup()}>
            <img src={"/public/images/NewDashboardV2/new_close_icon.png"} />
          </div>
        </CalendarHeader>
        <PopupWrapper padding="0 25px 25px 25px" >
          <div className='heading'>
            {this.props.t("Hey")} {userName}, {this.props.t("Welcome to Woliba Challenge Program!")}
          </div>
          <div className='about'>
            {this.props.t("About The Challenge")}
          </div>
          <div className='image'>
            <img src={ImageUrl +"/"+challengeData.image} />
          </div>
          <div className='type'>
            {challengeData.event_type=="Total Calories"?<img src={ImageUrl+'/'+"ChallengeDetailsScreen/Calories.svg"} />:challengeData.event_type=="Total Steps"?<img src={ImageUrl+'/'+"ChallengeDetailsScreen/Steps.svg"} />:<img src={ImageUrl+'/'+"ChallengeDetailsScreen/Distance.svg"} />}
            <div className='name'>{this.props.t(challengeData.title)}</div>
          </div>
          {/* <div className='aboutChallenge'>
            {this.props.t(challengeData.body)}
          </div> */}
          <div className='aboutChallenge'>
            <div>{parse(this.props.t(challengeData.body))}</div>
          </div>
        </PopupWrapper>
        <ButtonWrapper margin="25px" widthValue="430px">
          <div className='button' onClick={history.location.pathname.includes("/company/challenges/")?() => history.push(`/company/challenges/activity-launch/${challengeData.id}`):() => history.push(`/challenges/activity-launch/${challengeData.id}`)}>
            {this.props.t("Let’s Get Started")}
          </div>
        </ButtonWrapper>
      </StyledModal>
    );
  }
}

AboutChallengePopup.propTypes = {
  show: PropTypes.bool,
  challengeData: PropTypes.array,
  userName: PropTypes.string,
  hideAboutChallengePopup: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func
};

export default ((withTranslation())(AboutChallengePopup));