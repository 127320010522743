import React from 'react';
// import CKEditor from "react-ckeditor-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';


const Editor = ({onChange, content, placeholder, disabled}) => {
  if (content === null) {
    return null
  }

  return (
    <CKEditor
      data={content}
      // events={{
      //   "change": onChange
      // }}
      onChange={(evt,editor)=>onChange(editor)}
      editor={ ClassicEditor }
      config={{
        placeholder: placeholder || "Write description here...",
        isReadOnly: disabled,
        toolbar: {
          items: [
            'undo',
            'redo',
            'heading',
            'bold',
            'italic',
            'bulletedList',
            'numberedList'
          ],
        },
        extraPlugins: [(editor) => {
          editor.on('ready', () => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                'padding-left', '12px',
                editor.editing.view.document.getRoot()
              );
            });
          });
        }]

      }}
      disabled={disabled}

    />
  )
};

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default Editor;